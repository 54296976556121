.vdevice-mob-sm,
.vdevice-mob,
.vdevice-mob-l,
.vdevice-tab,
.vdevice-tab-l {
  .vpwa {
    display: none;
  }
}

// mobile responsive

.vdevice-mob-sm,
.vdevice-mob,
.vdevice-mob-l {
  body,
  .vonboarding-layout {
    background: var(--v-bg-secondary);
  }
  .vheader-menu-left,
  .vheader-menu-right {
    padding: 1rem 0.5rem 1rem 0rem;
  }
  .vdir-rtl {
    .vheader-menu-left,
    .vheader-menu-right {
      padding: 1rem 0.5rem;
    }
  }
  .profile-wrapper {
    text-align: center;
  }
  .profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
  }
  .profile-left {
    width: 100%;
  }
  .profile-right {
    width: 100%;
  }
}

.vdevice-mob-sm {
  select#myworkspace {
    width: 280px !important;
  }
}

.vdevice-mob,
.vdevice-mob-l {
  select#myworkspace {
    width: 320px !important;
  }
}
.vdevice-tab,
.vdevice-tab-l,
.vdevice-lap,
.vdevice-desk {
  .vdir-rtl {
    .profile-container {
      flex-direction: row-reverse;
    }
  }
}
